import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import {useOutlet} from 'reconnect.js';
import ReactPlayer from 'react-player';
import RectButton from '../../../Components/SnowOwl/RectButton';
import * as AppActions from '../../../AppActions';
import Section from '../../../Components/SnowOwl/Section';
import ArticleItem from '../../../Components/SnowOwl/ArticleItem';
import ProductItem from '../../../Components/SnowOwl/ProductItem';

function LandingPage(props) {
  const {landing} = props;
  const [products, setProducts] = useState([]);
  const [articles, setArticles] = useState([]);
  const [user] = useOutlet('user');

  useEffect(() => {
    if (!landing) {
      return;
    }

    const _fetchSite = async () => {
      try {
        AppActions.setLoading(true);

        let sortedProducts = [];
        let {results: _products} = await JStorage.fetchDocuments(
          'product',
          {
            $or: landing?.custom_layout?.products.map((id) => ({
              _id: {$oid: id},
            })),
          },
          null,
          {
            limit: 6,
            offset: 0,
          },
        );
        for (let i = 0; i < landing?.custom_layout?.products.length; i++) {
          let instance = _products.find(
            (_p) => _p.id === landing?.custom_layout.products[i],
          );
          sortedProducts.push(instance);
        }

        setProducts(sortedProducts);

        let sortedArticles = [];
        const {results: _articles} = await JStorage.fetchDocuments(
          'Article_Default',
          {
            $or: landing?.custom_layout?.articles.map((id) => ({
              _id: {$oid: id},
            })),
          },
        );
        for (let i = 0; i < landing?.custom_layout.articles.length; i++) {
          let instance = _articles.find(
            (_a) => _a.id === landing?.custom_layout.articles[i],
          );
          sortedArticles.push(instance);
        }

        setArticles(sortedArticles);
      } catch (e) {
        console.warn(e);
      } finally {
        AppActions.setLoading(false);
      }
    };
    _fetchSite().then(() => {});
  }, [landing]);

  if (!landing) {
    return <StyledLandingPage />;
  }

  return (
    <StyledLandingPage>
      <div
        className="hero-banner"
        style={{
          backgroundImage: `url("${
            landing?.custom_layout?.hero_banner.images[0].expected_url || ''
          }")`,
        }}>
        <div className="content">
          <div className="caption">
            <h1>Discover and find your NFTs!</h1>
            <small>SnowyOwl is the best NFT market in Taiwan</small>
          </div>
          <RectButton
            onClick={async () => {
              await AppActions.navigate('/collections', {
                loading: 800,
              });
            }}>
            Explore
          </RectButton>
        </div>
      </div>

      <Section
        title="News"
        subtitle="snow owl is the best NFT market in Taiwan">
        <div className="article-list">
          {articles.map((article, i) => {
            return (
              <ArticleItem
                onClick={async () => {
                  await AppActions.navigate(`/article?id=${article.id}`);
                }}
                image={article.image}
                key={i}
                title={article.title}
                date={article.date}
                description={article.outline}
              />
            );
          })}
        </div>
      </Section>

      <Section
        title="HOT NFTs"
        subtitle="snow owl is the best NFT market in Taiwan">
        <div className="product-list">
          {products.map((product) => {
            const showPrice =
              !!user || !landing?.custom_layout?.hide_price_for_user_not_login;
            return (
              <ProductItem
                key={product.id}
                title={product.name}
                subtitle={product.blockchain}
                price={product.price}
                image={product.images?.[0].expected_url || ''}
                showPrice={showPrice}
                onClick={async () => {
                  await AppActions.navigate(`/product?id=${product.id}`);
                }}
              />
            );
          })}
          {new Array(5).fill('product').map((filler, index) => (
            <div className="filler" key={index} />
          ))}
        </div>
        <RectButton
          style={{alignSelf: 'center', marginTop: 20}}
          outline={false}
          onClick={async () => {
            await AppActions.navigate(`collections`);
          }}>
          View All
        </RectButton>
      </Section>

      <Section
        title="Meet Us"
        subtitle="snow owl is the best NFT market in Taiwan"
        contentMaxWidth={800}>
        <div
          style={{
            position: 'relative',
            paddingTop: '56.25%',
          }}>
          <ReactPlayer
            url={landing?.custom_layout?.youtube_link}
            width="100%"
            height="100%"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
            }}
          />
        </div>
      </Section>
    </StyledLandingPage>
  );
}

const StyledLandingPage = styled.div`
  padding-top: var(--topNavBarHeight);
  background-color: var(--snow-owl-theme);
  min-height: calc(100vh - var(--bottomSiteFooterHeight));
  & > .hero-banner {
    width: 100%;
    aspect-ratio: calc(1440 / 500);
    background-color: var(--snow-owl-theme);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: grid;
    place-items: center;
    & > .content {
      min-height: 75%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-around;
      padding: 0 20px;
      & > .caption {
        filter: drop-shadow(0 0 10px #000);
        & > h1 {
          font-size: 48px;
          color: var(--snow-owl-theme-font-color);
          line-height: 3rem;
        }
        & > small {
          font-size: 14px;
          color: var(--snow-owl-theme-font-color);
          line-height: 3rem;
        }
      }
    }
  }

  & .article-list {
    display: flex;
    flex-direction: column;
  }

  & .product-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    & > .filler {
      height: 1px;
      width: calc((1200px / 5) - (4 * 5px));
      margin: 5px;
    }

    @media screen and (max-width: 767px) {
      justify-content: center;
    }
  }

  @media screen and (max-width: 767px) {
    & > .hero-banner {
      aspect-ratio: calc(900 / 1440);

      & > .content {
        min-height: 45%;
        & > .caption {
          & > h1 {
            font-size: 24px;
            color: var(--snow-owl-theme-font-color);
            line-height: 3rem;
          }
          & > small {
            font-size: 12px;
            color: var(--snow-owl-theme-font-color);
            line-height: 3rem;
          }
        }
      }
    }

    & .article-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  @media screen and (max-width: 575px) {
    & .article-list {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }
  }
`;

export default LandingPage;
