import React from 'react';
import styled, {css} from 'styled-components';

const RectButton = ({children, outline, ...rest}) => {
  return (
    <StyledRectButton outline={outline} {...rest}>
      {children}
    </StyledRectButton>
  );
};

const PRIMARY = css`
  background-color: var(--snow-owl-theme-button-hover-color);
  color: var(--snow-owl-light-16) !important;
  &:hover {
    background-color: var(--snow-owl-theme-color);
    color: var(--snow-owl-theme-button-hover-color) !important;
    border: 1px solid var(--snow-owl-theme-button-hover-color);
  }
`;

const OUTLINE = css`
  background-color: var(--snow-owl-theme-color);
  color: var(--snow-owl-theme-button-hover-color) !important;
  border: 1px solid var(--snow-owl-theme-button-hover-color);
  &:hover {
    background-color: var(--snow-owl-theme-button-hover-color);
    color: var(--snow-owl-theme-font-color) !important;
  }
`;

const StyledRectButton = styled.button`
  outline: none;
  border: 1px solid transparent;
  padding: 10px 60px;
  border-radius: 10px;
  cursor: pointer;
  box-sizing: border-box;
  transition: all 300ms ease;
  ${({outline}) => (outline ? OUTLINE : PRIMARY)};
`;

export default RectButton;
